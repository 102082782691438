package com.zegreatrob.coupling.client.components.pairassignments

import kotlin.Boolean
import kotlin.String
import kotlin.Unit
import react.ChildrenBuilder
import react.ElementType
import react.Key
import react.ReactNode
import react.create

public operator fun DroppableThingProps.component1(): String = itemType

public operator fun DroppableThingProps.component2(): (String) -> Unit = dropCallback

public operator fun DroppableThingProps.component3(): (Boolean) -> ReactNode = children

public operator fun DroppableThingProps.component4(): Key? = key

public fun ElementType<DroppableThingProps>.create(
  itemType: String,
  dropCallback: (String) -> Unit,
  key: Key? = null,
  children: ChildrenBuilder.(Boolean) -> Unit = { _ -> },
): ReactNode {
   return create {
         this.itemType = itemType
  this.dropCallback = dropCallback
  this.children = fun (cp0: kotlin.Boolean): react.ReactNode = react.Fragment.create(block = { children(cp0) })
  ;
  key?.let { this.key = it }
         }
}
