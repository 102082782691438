package com.zegreatrob.coupling.client.components.stats

import com.zegreatrob.coupling.client.components.graphing.CouplingResponsiveLineProps
import com.zegreatrob.coupling.model.PlayerPair
import com.zegreatrob.coupling.model.party.PartyDetails
import com.zegreatrob.coupling.model.player.Player
import kotlin.Int
import kotlin.collections.List
import kotlin.time.Duration
import react.ChildrenBuilder
import react.FC
import react.Key
import react.create

public fun ChildrenBuilder.PartyStatistics(
  party: PartyDetails,
  players: List<Player>,
  pairs: List<PlayerPair>,
  spinsUntilFullRotation: Int,
  medianSpinDuration: Duration? = null,
  chartComponent: FC<CouplingResponsiveLineProps>? = null,
  key: Key? = null,
) {
    val component = (PartyStatistics.unsafeCast<FC<PartyStatisticsProps>>())
    component {
         this.party = party
  this.players = players
  this.pairs = pairs
  this.spinsUntilFullRotation = spinsUntilFullRotation
  medianSpinDuration?.let { this.medianSpinDuration = it }
  chartComponent?.let { this.chartComponent = it }
  key?.let { this.key = it }
         }
}
