package com.zegreatrob.coupling.client.demo

import react.ChildrenBuilder
import react.FC
import react.Key
import react.create

public fun ChildrenBuilder.LoadingPageFrame(state: LoadingAnimationState, key: Key? = null) {
    val component = (LoadingPageFrame.unsafeCast<FC<LoadingPageFrameProps>>())
    component {
         this.state = state
  key?.let { this.key = it }
         }
}
