package com.zegreatrob.coupling.client.components.party

import com.zegreatrob.coupling.model.party.PartyDetails
import kotlin.Int
import react.ChildrenBuilder
import react.FC
import react.Key
import react.create

public fun ChildrenBuilder.PartyCardHeader(
  party: PartyDetails,
  size: Int,
  key: Key? = null,
) {
    val component = (PartyCardHeader.unsafeCast<FC<PartyCardHeaderProps>>())
    component {
         this.party = party
  this.size = size
  key?.let { this.key = it }
         }
}
