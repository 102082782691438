package com.zegreatrob.coupling.client.components.slack

import com.zegreatrob.coupling.action.party.SaveSlackIntegrationCommand
import com.zegreatrob.coupling.client.components.DispatchFunc
import com.zegreatrob.coupling.model.party.PartyDetails
import kotlin.String
import kotlin.collections.List
import react.ChildrenBuilder
import react.FC
import react.Key
import react.create

public fun ChildrenBuilder.SlackConnectPageContent(
  parties: List<PartyDetails>,
  slackTeam: String,
  slackChannel: String,
  dispatchFunc: DispatchFunc<SaveSlackIntegrationCommand.Dispatcher>,
  key: Key? = null,
) {
    val component = (SlackConnectPageContent.unsafeCast<FC<SlackConnectPageContentProps>>())
    component {
         this.parties = parties
  this.slackTeam = slackTeam
  this.slackChannel = slackChannel
  this.dispatchFunc = dispatchFunc
  key?.let { this.key = it }
         }
}
