package com.zegreatrob.coupling.client.components.graphing.`external`.nivo

import js.objects.jso
import kotlin.Number

public operator fun NivoChartMargin.component1(): Number = top

public operator fun NivoChartMargin.component2(): Number = right

public operator fun NivoChartMargin.component3(): Number = bottom

public operator fun NivoChartMargin.component4(): Number = left

public fun NivoChartMargin(
  top: Number,
  right: Number,
  bottom: Number,
  left: Number,
): NivoChartMargin {
    return jso<NivoChartMargin> {
         this.top = top
  this.right = right
  this.bottom = bottom
  this.left = left
         }
}
