package com.zegreatrob.coupling.client.party

import com.zegreatrob.coupling.model.party.PartyDetails
import kotlin.collections.List
import react.ChildrenBuilder
import react.FC
import react.Key
import react.create

public fun ChildrenBuilder.PartyList(parties: List<PartyDetails>, key: Key? = null) {
    val component = (PartyList.unsafeCast<FC<PartyListProps>>())
    component {
         this.parties = parties
  key?.let { this.key = it }
         }
}
