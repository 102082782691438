package com.zegreatrob.coupling.client.components.pairassignments

import com.zegreatrob.coupling.model.party.PartyDetails
import react.ChildrenBuilder
import react.FC
import react.Key
import react.create

public fun ChildrenBuilder.PrepareToSpinButton(party: PartyDetails, key: Key? = null) {
    val component = (PrepareToSpinButton.unsafeCast<FC<PrepareToSpinButtonProps>>())
    component {
         this.party = party
  key?.let { this.key = it }
         }
}
