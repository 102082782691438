package com.zegreatrob.coupling.client.components.party

import com.zegreatrob.coupling.model.Boost
import com.zegreatrob.coupling.model.party.PartyDetails
import kotlin.Int
import react.ChildrenBuilder
import react.FC
import react.Key
import react.create

public fun ChildrenBuilder.PartyCard(
  party: PartyDetails,
  size: Int? = null,
  boost: Boost? = null,
  key: Key? = null,
) {
    val component = (PartyCard.unsafeCast<FC<PartyCardProps>>())
    component {
         this.party = party
  size?.let { this.size = it }
  boost?.let { this.boost = it }
  key?.let { this.key = it }
         }
}
