package com.zegreatrob.coupling.client.user

import com.zegreatrob.coupling.model.user.SubscriptionDetails
import com.zegreatrob.coupling.model.user.UserDetails
import kotlin.String
import react.ChildrenBuilder
import react.FC
import react.Key
import react.create

public fun ChildrenBuilder.SponsorCouplingButton(
  user: UserDetails,
  subscription: SubscriptionDetails? = null,
  stripeAdminCode: String,
  stripePurchaseCode: String,
  key: Key? = null,
) {
    val component = (SponsorCouplingButton.unsafeCast<FC<SponsorCouplingButtonProps>>())
    component {
         this.user = user
  subscription?.let { this.subscription = it }
  this.stripeAdminCode = stripeAdminCode
  this.stripePurchaseCode = stripePurchaseCode
  key?.let { this.key = it }
         }
}
