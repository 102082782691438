package com.zegreatrob.coupling.client.components.player

import com.zegreatrob.coupling.model.party.PartyId
import com.zegreatrob.coupling.model.player.Player
import csstype.PropertiesBuilder
import kotlin.String
import kotlin.Unit
import kotlin.collections.List
import react.ChildrenBuilder
import react.FC
import react.Key
import react.create
import web.cssom.ClassName

public fun ChildrenBuilder.PlayerRoster(
  label: String? = null,
  players: List<Player>,
  partyId: PartyId,
  className: ClassName? = null,
  cssOverrides: ((PropertiesBuilder) -> Unit)? = null,
  key: Key? = null,
) {
    val component = (PlayerRoster.unsafeCast<FC<PlayerRosterProps>>())
    component {
         label?.let { this.label = it }
  this.players = players
  this.partyId = partyId
  className?.let { this.className = it }
  cssOverrides?.let { this.cssOverrides = it }
  key?.let { this.key = it }
         }
}
