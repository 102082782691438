package com.zegreatrob.coupling.client.components

import com.zegreatrob.coupling.model.player.Player
import kotlin.Unit
import kotlin.collections.Iterable
import react.ChildrenBuilder
import react.ElementType
import react.Key
import react.ReactNode
import react.create
import web.cssom.Angle

public operator fun TiltedPlayerListProps.component1(): Iterable<Player> = playerList

public operator fun TiltedPlayerListProps.component2(): (Angle, Player) -> ReactNode = children

public operator fun TiltedPlayerListProps.component3(): Key? = key

public fun ElementType<TiltedPlayerListProps>.create(
  playerList: Iterable<Player>,
  key: Key? = null,
  children: ChildrenBuilder.(Angle, Player) -> Unit = { _,_ -> },
): ReactNode {
   return create {
         this.playerList = playerList
  this.children = fun (cp0: web.cssom.Angle, cp1: com.zegreatrob.coupling.model.player.Player): react.ReactNode = react.Fragment.create(block = { children(cp0,cp1) })
  ;
  key?.let { this.key = it }
         }
}
