package com.zegreatrob.coupling.client.contribution

import com.zegreatrob.coupling.client.routing.Commander
import com.zegreatrob.coupling.model.party.PartyDetails
import kotlin.Int
import react.ChildrenBuilder
import react.FC
import react.Key
import react.create

public fun ChildrenBuilder.ContributionVisualization(
  commander: Commander,
  party: PartyDetails,
  spinsUntilFullRotation: Int,
  key: Key? = null,
) {
    val component = (ContributionVisualization.unsafeCast<FC<ContributionVisualizationProps>>())
    component {
         this.commander = commander
  this.party = party
  this.spinsUntilFullRotation = spinsUntilFullRotation
  key?.let { this.key = it }
         }
}
