package com.zegreatrob.coupling.client.components.pin

import com.zegreatrob.coupling.model.pin.Pin
import com.zegreatrob.coupling.model.pin.PinId
import kotlin.Unit
import kotlin.js.Json
import react.ChildrenBuilder
import react.FC
import react.Key
import react.create

public fun ChildrenBuilder.DraggablePinButton(
  pin: Pin,
  scale: PinButtonScale,
  endCallback: (PinId, Json?) -> Unit,
  key: Key? = null,
) {
    val component = (DraggablePinButton.unsafeCast<FC<DraggablePinButtonProps>>())
    component {
         this.pin = pin
  this.scale = scale
  this.endCallback = endCallback
  key?.let { this.key = it }
         }
}
