package com.zegreatrob.coupling.client.components

import kotlin.String
import kotlin.Unit
import react.ChildrenBuilder
import react.FC
import react.Key
import react.create

public fun ChildrenBuilder.Reminder(
  id: String,
  key: Key? = null,
  children: ChildrenBuilder.() -> Unit = {  -> },
) {
    val component = (Reminder.unsafeCast<FC<ReminderProps>>())
    component {
         this.id = id
  this.children = react.Fragment.create(block = { children() })
  key?.let { this.key = it }
         }
}
