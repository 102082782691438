package com.zegreatrob.coupling.client.components.contribution

import com.zegreatrob.coupling.model.party.PartyDetails
import kotlin.Unit
import react.ChildrenBuilder
import react.FC
import react.Key
import react.create

public fun ChildrenBuilder.ContributionContentFrame(
  party: PartyDetails,
  key: Key? = null,
  children: ChildrenBuilder.() -> Unit = {  -> },
) {
    val component = (ContributionContentFrame.unsafeCast<FC<ContributionContentFrameProps>>())
    component {
         this.party = party
  this.children = react.Fragment.create(block = { children() })
  key?.let { this.key = it }
         }
}
