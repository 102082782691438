package com.zegreatrob.coupling.client.components.player

import com.zegreatrob.coupling.action.player.SavePlayerCommand
import com.zegreatrob.coupling.client.components.DispatchFunc
import com.zegreatrob.coupling.model.player.Player
import kotlin.Unit
import kotlin.collections.List
import react.ChildrenBuilder
import react.FC
import react.Key
import react.create

public fun <D : SavePlayerCommand.Dispatcher> ChildrenBuilder.UpdatingPlayerList(
  players: List<Player>,
  dispatchFunc: DispatchFunc<D>,
  key: Key? = null,
  children: ChildrenBuilder.(List<Player>, DispatchFunc<D>) -> Unit = { _,_ -> },
) {
    val component = (UpdatingPlayerList.unsafeCast<FC<UpdatingPlayerListProps<D>>>())
    component {
         this.players = players
  this.dispatchFunc = dispatchFunc
  this.children = fun (cp0: kotlin.collections.List<com.zegreatrob.coupling.model.player.Player>, cp1: com.zegreatrob.coupling.client.components.DispatchFunc<D>): react.ReactNode = react.Fragment.create(block = { children(cp0,cp1) })
  ;
  key?.let { this.key = it }
         }
}
