package com.zegreatrob.coupling.client.components.spin

import com.zegreatrob.coupling.action.SpinCommand
import com.zegreatrob.coupling.client.components.DispatchFunc
import com.zegreatrob.coupling.model.pairassignmentdocument.PairAssignmentDocument
import com.zegreatrob.coupling.model.party.PartyDetails
import com.zegreatrob.coupling.model.pin.Pin
import com.zegreatrob.coupling.model.player.Player
import kotlin.collections.List
import react.ChildrenBuilder
import react.FC
import react.Key
import react.create

public fun ChildrenBuilder.PrepareSpin(
  party: PartyDetails,
  players: List<Player>,
  currentPairsDoc: PairAssignmentDocument? = null,
  pins: List<Pin>,
  dispatchFunc: DispatchFunc<SpinCommand.Dispatcher>,
  key: Key? = null,
) {
    val component = (PrepareSpin.unsafeCast<FC<PrepareSpinProps>>())
    component {
         this.party = party
  this.players = players
  currentPairsDoc?.let { this.currentPairsDoc = it }
  this.pins = pins
  this.dispatchFunc = dispatchFunc
  key?.let { this.key = it }
         }
}
