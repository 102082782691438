package com.zegreatrob.coupling.client.components.party

import com.zegreatrob.coupling.action.secret.DeleteSecretCommand
import com.zegreatrob.coupling.client.components.DispatchFunc
import com.zegreatrob.coupling.model.party.PartyId
import com.zegreatrob.coupling.model.party.Secret
import kotlin.Unit
import kotlin.collections.List
import react.ChildrenBuilder
import react.FC
import react.Key
import react.create

public fun ChildrenBuilder.SecretView(
  partyId: PartyId,
  secrets: List<Secret>,
  dispatcher: DispatchFunc<DeleteSecretCommand.Dispatcher>,
  reload: () -> Unit,
  key: Key? = null,
) {
    val component = (SecretView.unsafeCast<FC<SecretViewProps>>())
    component {
         this.partyId = partyId
  this.secrets = secrets
  this.dispatcher = dispatcher
  this.reload = reload
  key?.let { this.key = it }
         }
}
