package com.zegreatrob.coupling.client.components.party

import kotlin.Double
import react.ChildrenBuilder
import react.FC
import react.Key
import react.create

public fun ChildrenBuilder.CouplingLogo(
  width: Double,
  height: Double,
  key: Key? = null,
) {
    val component = (CouplingLogo.unsafeCast<FC<CouplingLogoProps>>())
    component {
         this.width = width
  this.height = height
  key?.let { this.key = it }
         }
}
