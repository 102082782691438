package com.zegreatrob.coupling.client.routing

import com.zegreatrob.coupling.client.ClientDispatcher
import com.zegreatrob.coupling.client.components.DispatchFunc
import com.zegreatrob.react.dataloader.ReloadFunc
import com.zegreatrob.testmints.action.async.SuspendAction
import kotlin.Unit
import react.ChildrenBuilder
import react.FC
import react.Key
import react.create

public fun <R> ChildrenBuilder.CouplingQuery(
  query: SuspendAction<ClientDispatcher, R?>,
  commander: Commander,
  key: Key? = null,
  children: ChildrenBuilder.(
    ReloadFunc,
    DispatchFunc<ClientDispatcher>,
    R,
  ) -> Unit = { _,_,_ -> },
) {
    val component = (CouplingQuery.unsafeCast<FC<CouplingQueryProps<R>>>())
    component {
         this.query = query
  this.children = fun (
    cp0: com.zegreatrob.react.dataloader.ReloadFunc,
    cp1: com.zegreatrob.coupling.client.components.DispatchFunc<com.zegreatrob.coupling.client.ClientDispatcher>,
    cp2: R,
  ): react.ReactNode? = react.Fragment.create(block = { children(cp0,cp1,cp2) })
  ;
  this.commander = commander
  key?.let { this.key = it }
         }
}
