package com.zegreatrob.coupling.client.contribution

import com.zegreatrob.coupling.json.GqlContributionWindow
import com.zegreatrob.coupling.model.Contribution
import kotlin.collections.List
import react.ChildrenBuilder
import react.FC
import react.Key
import react.create

public fun ChildrenBuilder.AllEaseLineGraph(
  `data`: List<Contribution>,
  window: GqlContributionWindow,
  key: Key? = null,
) {
    val component = (AllEaseLineGraph.unsafeCast<FC<AllEaseLineGraphProps>>())
    component {
         this.data = data
  this.window = window
  key?.let { this.key = it }
         }
}
