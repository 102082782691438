package com.zegreatrob.coupling.client.pairassignments

import com.zegreatrob.coupling.action.pairassignmentdocument.DeletePairAssignmentsCommand
import com.zegreatrob.coupling.action.pairassignmentdocument.SavePairAssignmentsCommand
import com.zegreatrob.coupling.client.components.Controls
import com.zegreatrob.coupling.model.Boost
import com.zegreatrob.coupling.model.pairassignmentdocument.PairAssignmentDocument
import com.zegreatrob.coupling.model.party.PartyDetails
import com.zegreatrob.coupling.model.player.Player
import kotlin.Boolean
import kotlin.collections.List
import react.ChildrenBuilder
import react.FC
import react.Key
import react.create

public fun <D> ChildrenBuilder.SocketedPairAssignments(
  party: PartyDetails,
  boost: Boost? = null,
  players: List<Player>,
  pairAssignments: PairAssignmentDocument? = null,
  controls: Controls<D>,
  allowSave: Boolean,
  key: Key? = null,
) where D : SavePairAssignmentsCommand.Dispatcher, D : DeletePairAssignmentsCommand.Dispatcher {
    val component = (SocketedPairAssignments.unsafeCast<FC<SocketedPairAssignmentsProps<D>>>())
    component {
         this.party = party
  boost?.let { this.boost = it }
  this.players = players
  pairAssignments?.let { this.pairAssignments = it }
  this.controls = controls
  this.allowSave = allowSave
  key?.let { this.key = it }
         }
}
