package com.zegreatrob.coupling.client.integration

import com.zegreatrob.coupling.model.party.PartyDetails
import com.zegreatrob.coupling.model.party.PartyIntegration
import kotlin.String
import react.ChildrenBuilder
import react.FC
import react.Key
import react.create

public fun ChildrenBuilder.IntegrationContent(
  party: PartyDetails,
  integration: PartyIntegration? = null,
  addToSlackUrl: String,
  key: Key? = null,
) {
    val component = (IntegrationContent.unsafeCast<FC<IntegrationContentProps>>())
    component {
         this.party = party
  integration?.let { this.integration = it }
  this.addToSlackUrl = addToSlackUrl
  key?.let { this.key = it }
         }
}
