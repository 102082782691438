package com.zegreatrob.coupling.client.components.pin

import com.zegreatrob.coupling.model.Boost
import com.zegreatrob.coupling.model.party.PartyDetails
import com.zegreatrob.coupling.model.pin.Pin
import kotlin.Unit
import kotlin.collections.List
import react.ChildrenBuilder
import react.FC
import react.Key
import react.create
import react.dom.events.ChangeEvent

public fun ChildrenBuilder.PinConfigContent(
  party: PartyDetails,
  boost: Boost? = null,
  pin: Pin,
  pinList: List<Pin>,
  onChange: (ChangeEvent<*>) -> Unit,
  onSubmit: () -> Unit,
  onRemove: (() -> Unit)? = null,
  key: Key? = null,
) {
    val component = (PinConfigContent.unsafeCast<FC<PinConfigContentProps>>())
    component {
         this.party = party
  boost?.let { this.boost = it }
  this.pin = pin
  this.pinList = pinList
  this.onChange = onChange
  this.onSubmit = onSubmit
  onRemove?.let { this.onRemove = it }
  key?.let { this.key = it }
         }
}
