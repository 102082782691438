package com.zegreatrob.coupling.client.components.contribution

import com.zegreatrob.coupling.model.Contribution
import com.zegreatrob.coupling.model.party.PartyId
import com.zegreatrob.coupling.model.player.Player
import kotlin.Unit
import kotlin.collections.List
import react.ChildrenBuilder
import react.FC
import react.Key
import react.create
import web.html.HTMLElement

public fun ChildrenBuilder.ContributionCardHeader(
  partyId: PartyId,
  contribution: Contribution,
  players: List<Player>,
  onPlayerClick: ((Player, HTMLElement) -> Unit)? = null,
  key: Key? = null,
) {
    val component = (ContributionCardHeader.unsafeCast<FC<ContributionCardHeaderProps>>())
    component {
         this.partyId = partyId
  this.contribution = contribution
  this.players = players
  onPlayerClick?.let { this.onPlayerClick = it }
  key?.let { this.key = it }
         }
}
