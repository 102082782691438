package com.zegreatrob.coupling.client.components.party

import com.zegreatrob.coupling.action.secret.CreateSecretCommand
import com.zegreatrob.coupling.action.secret.DeleteSecretCommand
import com.zegreatrob.coupling.client.components.DispatchFunc
import com.zegreatrob.coupling.model.Boost
import com.zegreatrob.coupling.model.party.PartyDetails
import com.zegreatrob.coupling.model.party.Secret
import kotlin.Unit
import kotlin.collections.List
import react.ChildrenBuilder
import react.FC
import react.Key
import react.create

public fun <D> ChildrenBuilder.PartySecretLayout(
  partyDetails: PartyDetails,
  secrets: List<Secret>,
  boost: Boost? = null,
  dispatcher: DispatchFunc<D>,
  reload: () -> Unit,
  key: Key? = null,
) where D : DeleteSecretCommand.Dispatcher, D : CreateSecretCommand.Dispatcher {
    val component = (PartySecretLayout.unsafeCast<FC<PartySecretsLayoutProps<D>>>())
    component {
         this.partyDetails = partyDetails
  this.secrets = secrets
  boost?.let { this.boost = it }
  this.dispatcher = dispatcher
  this.reload = reload
  key?.let { this.key = it }
         }
}
