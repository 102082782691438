package com.zegreatrob.coupling.client.components.stats

import com.zegreatrob.coupling.client.components.graphing.CouplingResponsiveLineProps
import com.zegreatrob.coupling.model.PlayerPair
import com.zegreatrob.coupling.model.player.Player
import kotlin.Int
import kotlin.collections.List
import kotlin.time.Duration
import react.ChildrenBuilder
import react.FC
import react.Key
import react.create

public fun ChildrenBuilder.PartyStatisticsContent(
  spinsUntilFullRotation: Int,
  players: List<Player>,
  medianSpinDuration: Duration? = null,
  pairs: List<PlayerPair>,
  chartComponent: FC<CouplingResponsiveLineProps>? = null,
  key: Key? = null,
) {
    val component = (PartyStatisticsContent.unsafeCast<FC<PartyStatisticsContentProps>>())
    component {
         this.spinsUntilFullRotation = spinsUntilFullRotation
  this.players = players
  medianSpinDuration?.let { this.medianSpinDuration = it }
  this.pairs = pairs
  chartComponent?.let { this.chartComponent = it }
  key?.let { this.key = it }
         }
}
