package com.zegreatrob.coupling.client.pin

import com.zegreatrob.coupling.model.party.PartyDetails
import com.zegreatrob.coupling.model.pin.Pin
import kotlin.collections.List
import react.ChildrenBuilder
import react.FC
import react.Key
import react.create

public fun ChildrenBuilder.PinList(
  party: PartyDetails,
  pins: List<Pin>,
  key: Key? = null,
) {
    val component = (PinList.unsafeCast<FC<PinListProps>>())
    component {
         this.party = party
  this.pins = pins
  key?.let { this.key = it }
         }
}
