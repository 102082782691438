package com.zegreatrob.coupling.client.components.pin

import com.zegreatrob.coupling.model.pin.Pin
import com.zegreatrob.coupling.model.pin.PinId
import kotlin.Unit
import kotlin.collections.List
import kotlin.js.Json
import react.ChildrenBuilder
import react.FC
import react.Key
import react.create
import web.cssom.ClassName

public fun ChildrenBuilder.PinSection(
  pinList: List<Pin>,
  scale: PinButtonScale? = null,
  className: ClassName? = null,
  endCallback: ((PinId, Json?) -> Unit)? = null,
  key: Key? = null,
) {
    val component = (PinSection.unsafeCast<FC<PinSectionProps>>())
    component {
         this.pinList = pinList
  scale?.let { this.scale = it }
  className?.let { this.className = it }
  endCallback?.let { this.endCallback = it }
  key?.let { this.key = it }
         }
}
