package com.zegreatrob.coupling.client.components.pin

import com.zegreatrob.coupling.action.pin.DeletePinCommand
import com.zegreatrob.coupling.action.pin.SavePinCommand
import com.zegreatrob.coupling.client.components.DispatchFunc
import com.zegreatrob.coupling.model.Boost
import com.zegreatrob.coupling.model.party.PartyDetails
import com.zegreatrob.coupling.model.pin.Pin
import kotlin.Unit
import kotlin.collections.List
import react.ChildrenBuilder
import react.FC
import react.Key
import react.create

public fun <D> ChildrenBuilder.PinConfig(
  party: PartyDetails,
  boost: Boost? = null,
  pin: Pin,
  pinList: List<Pin>,
  reload: () -> Unit,
  dispatchFunc: DispatchFunc<D>,
  key: Key? = null,
) where D : DeletePinCommand.Dispatcher, D : SavePinCommand.Dispatcher {
    val component = (PinConfig.unsafeCast<FC<PinConfigProps<D>>>())
    component {
         this.party = party
  boost?.let { this.boost = it }
  this.pin = pin
  this.pinList = pinList
  this.reload = reload
  this.dispatchFunc = dispatchFunc
  key?.let { this.key = it }
         }
}
