package com.zegreatrob.coupling.client.components

import com.zegreatrob.coupling.model.player.Player
import kotlin.Unit
import kotlin.collections.Iterable
import react.ChildrenBuilder
import react.FC
import react.Key
import react.create
import web.cssom.Angle

public fun ChildrenBuilder.TiltedPlayerList(
  playerList: Iterable<Player>,
  key: Key? = null,
  children: ChildrenBuilder.(Angle, Player) -> Unit = { _,_ -> },
) {
    val component = (TiltedPlayerList.unsafeCast<FC<TiltedPlayerListProps>>())
    component {
         this.playerList = playerList
  this.children = fun (cp0: web.cssom.Angle, cp1: com.zegreatrob.coupling.model.player.Player): react.ReactNode = react.Fragment.create(block = { children(cp0,cp1) })
  ;
  key?.let { this.key = it }
         }
}
