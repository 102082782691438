package com.zegreatrob.coupling.client.components.pairassignments.spin

import com.zegreatrob.coupling.model.pairassignmentdocument.PairAssignmentDocument
import com.zegreatrob.coupling.model.party.PartyDetails
import com.zegreatrob.coupling.model.player.Player
import kotlin.Boolean
import kotlin.Unit
import kotlin.collections.List
import react.ChildrenBuilder
import react.ElementType
import react.Key
import react.ReactNode
import react.create

public operator fun PairAssignmentsAnimatorProps.component1(): PartyDetails = party

public operator fun PairAssignmentsAnimatorProps.component2(): List<Player> = players

public operator fun PairAssignmentsAnimatorProps.component3(): PairAssignmentDocument = pairAssignments

public operator fun PairAssignmentsAnimatorProps.component4(): Boolean = enabled

public operator fun PairAssignmentsAnimatorProps.component5(): ReactNode? = children

public operator fun PairAssignmentsAnimatorProps.component6(): Key? = key

public fun ElementType<PairAssignmentsAnimatorProps>.create(
  party: PartyDetails,
  players: List<Player>,
  pairAssignments: PairAssignmentDocument,
  enabled: Boolean,
  key: Key? = null,
  children: ChildrenBuilder.() -> Unit = {  -> },
): ReactNode {
   return create {
         this.party = party
  this.players = players
  this.pairAssignments = pairAssignments
  this.enabled = enabled
  this.children = react.Fragment.create(block = { children() })
  key?.let { this.key = it }
         }
}
