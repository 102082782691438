package com.zegreatrob.coupling.client.components.stats

import com.zegreatrob.coupling.action.stats.PairReport
import react.ChildrenBuilder
import react.FC
import react.Key
import react.create

public fun ChildrenBuilder.PairReportView(pairReport: PairReport, key: Key? = null) {
    val component = (PairReportView.unsafeCast<FC<PairReportViewProps>>())
    component {
         this.pairReport = pairReport
  key?.let { this.key = it }
         }
}
