package com.zegreatrob.coupling.client.components

import com.zegreatrob.coupling.model.Message
import com.zegreatrob.coupling.model.party.PartyId
import kotlin.Boolean
import kotlin.String
import kotlin.Unit
import react.ChildrenBuilder
import react.ElementType
import react.Key
import react.ReactNode
import react.create

public operator fun CouplingWebsocketProps.component1(): PartyId = partyId

public operator fun CouplingWebsocketProps.component2(): (Message) -> Unit = onMessage

public operator fun CouplingWebsocketProps.component3(): (((Message) -> Unit)?) -> ReactNode = children

public operator fun CouplingWebsocketProps.component4(): String = token

public operator fun CouplingWebsocketProps.component5(): Boolean? = useSsl

public operator fun CouplingWebsocketProps.component6(): Key? = key

public fun ElementType<CouplingWebsocketProps>.create(
  partyId: PartyId,
  onMessage: (Message) -> Unit,
  token: String,
  useSsl: Boolean? = null,
  key: Key? = null,
  children: ChildrenBuilder.(((Message) -> Unit)?) -> Unit = { _ -> },
): ReactNode {
   return create {
         this.partyId = partyId
  this.onMessage = onMessage
  this.children = fun (cp0: ((com.zegreatrob.coupling.model.Message) -> kotlin.Unit)?): react.ReactNode = react.Fragment.create(block = { children(cp0) })
  ;
  this.token = token
  useSsl?.let { this.useSsl = it }
  key?.let { this.key = it }
         }
}
