package com.zegreatrob.coupling.client.components.player

import com.zegreatrob.coupling.model.party.PartyDetails
import com.zegreatrob.coupling.model.player.Player
import kotlin.collections.List
import react.ChildrenBuilder
import react.FC
import react.Key
import react.create

public fun ChildrenBuilder.TinyPlayerList(
  party: PartyDetails,
  players: List<Player>,
  key: Key? = null,
) {
    val component = (TinyPlayerList.unsafeCast<FC<TinyPlayerListProps>>())
    component {
         this.party = party
  this.players = players
  key?.let { this.key = it }
         }
}
