package com.zegreatrob.coupling.client.contribution

import com.zegreatrob.coupling.json.GqlContributionWindow
import com.zegreatrob.coupling.model.ContributionReport
import com.zegreatrob.coupling.model.pairassignmentdocument.CouplingPair
import kotlin.Pair
import kotlin.collections.List
import react.ChildrenBuilder
import react.FC
import react.Key
import react.create

public fun ChildrenBuilder.PairContributionsLineGraph(
  `data`: List<Pair<CouplingPair, ContributionReport>>,
  window: GqlContributionWindow,
  key: Key? = null,
) {
    val component = (PairContributionsLineGraph.unsafeCast<FC<PairContributionsLineGraphProps>>())
    component {
         this.data = data
  this.window = window
  key?.let { this.key = it }
         }
}
