package com.zegreatrob.coupling.client.components

import kotlin.Double
import kotlin.sequences.Sequence
import react.ChildrenBuilder
import react.FC
import react.Key
import react.ReactNode
import react.create

public fun <S> ChildrenBuilder.FrameRunner(
  sequence: Sequence<Frame<S>>,
  speed: Double,
  child: (S) -> ReactNode,
  key: Key? = null,
) {
    val component = (FrameRunner.unsafeCast<FC<FrameRunnerProps<S>>>())
    component {
         this.sequence = sequence
  this.speed = speed
  this.child = child
  key?.let { this.key = it }
         }
}
