package com.zegreatrob.coupling.client.components

import com.zegreatrob.coupling.action.player.SavePlayerCommand
import com.zegreatrob.coupling.model.party.PartyId
import com.zegreatrob.coupling.model.player.Player
import kotlin.Unit
import kotlin.collections.List
import popper.core.ReferenceElement
import react.ChildrenBuilder
import react.FC
import react.Key
import react.create

public fun ChildrenBuilder.ContributionPopUpMenu(
  partyId: PartyId,
  players: List<Player>,
  dispatchFunc: DispatchFunc<SavePlayerCommand.Dispatcher>,
  key: Key? = null,
  children: ChildrenBuilder.((ReferenceElement, Player) -> Unit) -> Unit = { _ -> },
) {
    val component = (ContributionPopUpMenu.unsafeCast<FC<ContributionPopUpMenuProps>>())
    component {
         this.partyId = partyId
  this.players = players
  this.dispatchFunc = dispatchFunc
  this.children = fun (cp0: (popper.core.ReferenceElement, com.zegreatrob.coupling.model.player.Player) -> kotlin.Unit): react.ReactNode = react.Fragment.create(block = { children(cp0) })
  ;
  key?.let { this.key = it }
         }
}
