package com.zegreatrob.coupling.client.routing

import com.zegreatrob.react.dataloader.DataLoadState
import kotlin.Unit
import react.ChildrenBuilder
import react.FC
import react.Key
import react.create

public fun ChildrenBuilder.animationFrame(
  state: DataLoadState<*>,
  key: Key? = null,
  children: ChildrenBuilder.() -> Unit = {  -> },
) {
    val component = (animationFrame.unsafeCast<FC<AnimationFrameProps>>())
    component {
         this.state = state
  this.children = react.Fragment.create(block = { children() })
  key?.let { this.key = it }
         }
}
