package com.zegreatrob.coupling.client.incubating

import com.zegreatrob.coupling.model.party.PartyDetails
import kotlin.String
import kotlin.collections.List
import react.ChildrenBuilder
import react.FC
import react.Key
import react.create

public fun ChildrenBuilder.AddToDiscordButton(
  partyList: List<PartyDetails>,
  discordClientId: String,
  key: Key? = null,
) {
    val component = (AddToDiscordButton.unsafeCast<FC<AddToDiscordButtonProps>>())
    component {
         this.partyList = partyList
  this.discordClientId = discordClientId
  key?.let { this.key = it }
         }
}
