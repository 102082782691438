package com.zegreatrob.coupling.client.components.pairassignments

import com.zegreatrob.coupling.action.pairassignmentdocument.DeletePairAssignmentsCommand
import com.zegreatrob.coupling.client.components.Controls
import com.zegreatrob.coupling.client.components.`external`.w3c.WindowFunctions
import com.zegreatrob.coupling.model.pairassignmentdocument.PairAssignmentDocument
import com.zegreatrob.coupling.model.party.PartyDetails
import react.ChildrenBuilder
import react.FC
import react.Key
import react.create

public fun ChildrenBuilder.PairAssignmentRow(
  party: PartyDetails,
  document: PairAssignmentDocument,
  controls: Controls<DeletePairAssignmentsCommand.Dispatcher>,
  windowFunctions: WindowFunctions? = null,
  key: Key? = null,
) {
    val component = (PairAssignmentRow.unsafeCast<FC<PairAssignmentRowProps>>())
    component {
         this.party = party
  this.document = document
  this.controls = controls
  windowFunctions?.let { this.windowFunctions = it }
  key?.let { this.key = it }
         }
}
