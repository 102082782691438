package com.zegreatrob.coupling.client.components.party

import com.zegreatrob.coupling.action.party.DeletePartyCommand
import com.zegreatrob.coupling.action.party.SavePartyCommand
import com.zegreatrob.coupling.client.components.DispatchFunc
import com.zegreatrob.coupling.model.Boost
import com.zegreatrob.coupling.model.party.PartyDetails
import kotlin.Boolean
import react.ChildrenBuilder
import react.FC
import react.Key
import react.create

public fun <D> ChildrenBuilder.PartyConfig(
  party: PartyDetails,
  boost: Boost? = null,
  dispatchFunc: DispatchFunc<D>,
  isNew: Boolean,
  key: Key? = null,
) where D : SavePartyCommand.Dispatcher, D : DeletePartyCommand.Dispatcher {
    val component = (PartyConfig.unsafeCast<FC<PartyConfigProps<D>>>())
    component {
         this.party = party
  boost?.let { this.boost = it }
  this.dispatchFunc = dispatchFunc
  this.isNew = isNew
  key?.let { this.key = it }
         }
}
