package com.zegreatrob.coupling.client.components.pairassignments

import kotlin.Boolean
import kotlin.String
import kotlin.Unit
import react.ChildrenBuilder
import react.FC
import react.Key
import react.create

public fun ChildrenBuilder.DroppableThing(
  itemType: String,
  dropCallback: (String) -> Unit,
  key: Key? = null,
  children: ChildrenBuilder.(Boolean) -> Unit = { _ -> },
) {
    val component = (DroppableThing.unsafeCast<FC<DroppableThingProps>>())
    component {
         this.itemType = itemType
  this.dropCallback = dropCallback
  this.children = fun (cp0: kotlin.Boolean): react.ReactNode = react.Fragment.create(block = { children(cp0) })
  ;
  key?.let { this.key = it }
         }
}
