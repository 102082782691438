package com.zegreatrob.coupling.client.components

import kotlin.Unit
import react.ChildrenBuilder
import react.FC
import react.Key
import react.create

public fun ChildrenBuilder.ConfigForm(
  onSubmit: (() -> Unit)? = null,
  onRemove: (() -> Unit)? = null,
  key: Key? = null,
  children: ChildrenBuilder.() -> Unit = {  -> },
) {
    val component = (ConfigForm.unsafeCast<FC<ConfigFormProps>>())
    component {
         onSubmit?.let { this.onSubmit = it }
  onRemove?.let { this.onRemove = it }
  this.children = react.Fragment.create(block = { children() })
  key?.let { this.key = it }
         }
}
