package com.zegreatrob.coupling.client.components.pairassignments.spin

import com.zegreatrob.coupling.client.components.spin.RosteredPairAssignments
import com.zegreatrob.coupling.model.party.PartyDetails
import react.ChildrenBuilder
import react.FC
import react.Key
import react.create

public fun ChildrenBuilder.SpinAnimationPanel(
  party: PartyDetails,
  rosteredPairAssignments: RosteredPairAssignments,
  state: SpinAnimationState,
  key: Key? = null,
) {
    val component = (SpinAnimationPanel.unsafeCast<FC<SpinAnimationPanelProps>>())
    component {
         this.party = party
  this.rosteredPairAssignments = rosteredPairAssignments
  this.state = state
  key?.let { this.key = it }
         }
}
