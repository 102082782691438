package com.zegreatrob.coupling.client.components

import com.zegreatrob.react.dataloader.DataLoadState
import react.ChildrenBuilder
import react.ElementType
import react.FC
import react.Key
import react.Props
import react.ReactNode
import react.create

public fun <T : Props> ChildrenBuilder.AsyncReactComponent(
  state: DataLoadState<ElementType<T>?>,
  useComponent: (ElementType<T>) -> ReactNode,
  key: Key? = null,
) {
    val component = (AsyncReactComponent.unsafeCast<FC<AsyncReactComponentProps<T>>>())
    component {
         this.state = state
  this.useComponent = useComponent
  key?.let { this.key = it }
         }
}
