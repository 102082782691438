package com.zegreatrob.coupling.client.components.pairassignments

import com.zegreatrob.coupling.action.pairassignmentdocument.DeletePairAssignmentsCommand
import com.zegreatrob.coupling.client.components.Controls
import com.zegreatrob.coupling.model.Boost
import com.zegreatrob.coupling.model.CouplingSocketMessage
import com.zegreatrob.coupling.model.pairassignmentdocument.PairAssignmentDocument
import com.zegreatrob.coupling.model.party.PartyDetails
import com.zegreatrob.coupling.model.player.Player
import kotlin.Boolean
import kotlin.Unit
import kotlin.collections.List
import react.ChildrenBuilder
import react.FC
import react.Key
import react.create

public fun ChildrenBuilder.PairAssignments(
  party: PartyDetails,
  boost: Boost? = null,
  players: List<Player>,
  pairs: PairAssignmentDocument? = null,
  setPairs: (PairAssignmentDocument) -> Unit,
  controls: Controls<DeletePairAssignmentsCommand.Dispatcher>,
  message: CouplingSocketMessage,
  allowSave: Boolean,
  key: Key? = null,
) {
    val component = (PairAssignments.unsafeCast<FC<PairAssignmentsProps>>())
    component {
         this.party = party
  boost?.let { this.boost = it }
  this.players = players
  pairs?.let { this.pairs = it }
  this.setPairs = setPairs
  this.controls = controls
  this.message = message
  this.allowSave = allowSave
  key?.let { this.key = it }
         }
}
