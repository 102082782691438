package com.zegreatrob.coupling.client.user

import com.zegreatrob.coupling.action.ApplyBoostCommand
import com.zegreatrob.coupling.client.components.DispatchFunc
import com.zegreatrob.coupling.model.Boost
import com.zegreatrob.coupling.model.party.PartyDetails
import com.zegreatrob.coupling.model.user.SubscriptionDetails
import com.zegreatrob.coupling.model.user.UserDetails
import com.zegreatrob.coupling.sdk.gql.GraphQuery
import kotlin.String
import kotlin.Unit
import kotlin.collections.List
import react.ChildrenBuilder
import react.FC
import react.Key
import react.create

public fun <D> ChildrenBuilder.UserConfig(
  user: UserDetails? = null,
  subscription: SubscriptionDetails? = null,
  partyList: List<PartyDetails>,
  dispatcher: DispatchFunc<D>,
  stripeAdminCode: String,
  stripePurchaseCode: String,
  boost: Boost? = null,
  reload: () -> Unit,
  key: Key? = null,
) where D : GraphQuery.Dispatcher, D : ApplyBoostCommand.Dispatcher {
    val component = (UserConfig.unsafeCast<FC<UserConfigProps<D>>>())
    component {
         user?.let { this.user = it }
  subscription?.let { this.subscription = it }
  this.partyList = partyList
  this.dispatcher = dispatcher
  this.stripeAdminCode = stripeAdminCode
  this.stripePurchaseCode = stripePurchaseCode
  boost?.let { this.boost = it }
  this.reload = reload
  key?.let { this.key = it }
         }
}
