package com.zegreatrob.coupling.client.components.graphing

import kotlin.Double
import kotlin.collections.List
import react.ChildrenBuilder
import react.FC
import react.Key
import react.create
import web.cssom.ClassName

public fun ChildrenBuilder.Heatmap(
  `data`: List<List<Double?>>,
  className: ClassName,
  key: Key? = null,
) {
    val component = (Heatmap.unsafeCast<FC<HeatmapProps>>())
    component {
         this.data = data
  this.className = className
  key?.let { this.key = it }
         }
}
