package com.zegreatrob.coupling.client.pairassignments.list

import com.zegreatrob.coupling.action.pairassignmentdocument.DeletePairAssignmentsCommand
import com.zegreatrob.coupling.client.components.Controls
import com.zegreatrob.coupling.client.components.`external`.w3c.WindowFunctions
import com.zegreatrob.coupling.model.pairassignmentdocument.PairAssignmentDocument
import com.zegreatrob.coupling.model.party.PartyDetails
import kotlin.collections.List
import react.ChildrenBuilder
import react.FC
import react.Key
import react.create

public fun ChildrenBuilder.History(
  party: PartyDetails,
  history: List<PairAssignmentDocument>,
  controls: Controls<DeletePairAssignmentsCommand.Dispatcher>,
  windowFunctions: WindowFunctions? = null,
  key: Key? = null,
) {
    val component = (History.unsafeCast<FC<HistoryProps>>())
    component {
         this.party = party
  this.history = history
  this.controls = controls
  windowFunctions?.let { this.windowFunctions = it }
  key?.let { this.key = it }
         }
}
