package com.zegreatrob.coupling.client.components

import com.zegreatrob.coupling.model.party.PartyDetails
import react.ChildrenBuilder
import react.FC
import react.Key
import react.create

public fun ChildrenBuilder.PartyNavigation(party: PartyDetails, key: Key? = null) {
    val component = (PartyNavigation.unsafeCast<FC<PartyNavigationProps>>())
    component {
         this.party = party
  key?.let { this.key = it }
         }
}
