package com.zegreatrob.coupling.client.components

import com.zegreatrob.coupling.model.Message
import com.zegreatrob.coupling.model.party.PartyId
import kotlin.Boolean
import kotlin.String
import kotlin.Unit
import react.ChildrenBuilder
import react.FC
import react.Key
import react.create

public fun ChildrenBuilder.CouplingWebsocket(
  partyId: PartyId,
  onMessage: (Message) -> Unit,
  token: String,
  useSsl: Boolean? = null,
  key: Key? = null,
  children: ChildrenBuilder.(((Message) -> Unit)?) -> Unit = { _ -> },
) {
    val component = (CouplingWebsocket.unsafeCast<FC<CouplingWebsocketProps>>())
    component {
         this.partyId = partyId
  this.onMessage = onMessage
  this.children = fun (cp0: ((com.zegreatrob.coupling.model.Message) -> kotlin.Unit)?): react.ReactNode = react.Fragment.create(block = { children(cp0) })
  ;
  this.token = token
  useSsl?.let { this.useSsl = it }
  key?.let { this.key = it }
         }
}
