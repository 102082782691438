package com.zegreatrob.coupling.client.components.pairassignments

import com.zegreatrob.coupling.model.pairassignmentdocument.PinnedPlayer
import com.zegreatrob.coupling.model.player.PlayerId
import kotlin.Boolean
import kotlin.Unit
import react.ElementType
import react.Key
import react.ReactNode
import react.create
import web.cssom.Angle

public operator fun DraggablePlayerProps.component1(): PinnedPlayer = pinnedPlayer

public operator fun DraggablePlayerProps.component2(): Boolean = zoomOnHover

public operator fun DraggablePlayerProps.component3(): Angle = tilt

public operator fun DraggablePlayerProps.component4(): (PlayerId) -> Unit = onPlayerDrop

public operator fun DraggablePlayerProps.component5(): Key? = key

public fun ElementType<DraggablePlayerProps>.create(
  pinnedPlayer: PinnedPlayer,
  zoomOnHover: Boolean,
  tilt: Angle,
  onPlayerDrop: (PlayerId) -> Unit,
  key: Key? = null,
): ReactNode {
   return create {
         this.pinnedPlayer = pinnedPlayer
  this.zoomOnHover = zoomOnHover
  this.tilt = tilt
  this.onPlayerDrop = onPlayerDrop
  key?.let { this.key = it }
         }
}
