package com.zegreatrob.coupling.client.components.graphing

import kotlin.Double
import kotlin.collections.List
import react.ElementType
import react.Key
import react.ReactNode
import react.create
import web.cssom.ClassName

public operator fun HeatmapProps.component1(): List<List<Double?>> = data

public operator fun HeatmapProps.component2(): ClassName = className

public operator fun HeatmapProps.component3(): Key? = key

public fun ElementType<HeatmapProps>.create(
  `data`: List<List<Double?>>,
  className: ClassName,
  key: Key? = null,
): ReactNode {
   return create {
         this.data = data
  this.className = className
  key?.let { this.key = it }
         }
}
