package com.zegreatrob.coupling.client.incubating

import com.zegreatrob.coupling.model.party.PartyDetails
import kotlin.String
import kotlin.collections.List
import react.ChildrenBuilder
import react.FC
import react.Key
import react.create

public fun ChildrenBuilder.IncubatingContent(
  discordClientId: String,
  addToSlackUrl: String,
  partyList: List<PartyDetails>,
  key: Key? = null,
) {
    val component = (IncubatingContent.unsafeCast<FC<IncubatingContentProps>>())
    component {
         this.discordClientId = discordClientId
  this.addToSlackUrl = addToSlackUrl
  this.partyList = partyList
  key?.let { this.key = it }
         }
}
