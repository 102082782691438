package com.zegreatrob.coupling.client.contribution

import com.zegreatrob.coupling.json.GqlContributionWindow
import com.zegreatrob.coupling.model.Contribution
import kotlin.collections.List
import react.ElementType
import react.Key
import react.ReactNode
import react.create

public operator fun StoryContributionGraphProps.component1(): List<Contribution> = data

public operator fun StoryContributionGraphProps.component2(): GqlContributionWindow = window

public operator fun StoryContributionGraphProps.component3(): Key? = key

public fun ElementType<StoryContributionGraphProps>.create(
  `data`: List<Contribution>,
  window: GqlContributionWindow,
  key: Key? = null,
): ReactNode {
   return create {
         this.data = data
  this.window = window
  key?.let { this.key = it }
         }
}
