package com.zegreatrob.coupling.client.components.player

import com.zegreatrob.coupling.model.Boost
import com.zegreatrob.coupling.model.party.PartyDetails
import com.zegreatrob.coupling.model.player.Player
import kotlin.Unit
import kotlin.collections.List
import react.ElementType
import react.Key
import react.ReactNode
import react.create
import react.dom.events.ChangeEvent

public operator fun PlayerConfigContentProps.component1(): PartyDetails = party

public operator fun PlayerConfigContentProps.component2(): Boost? = boost

public operator fun PlayerConfigContentProps.component3(): Player = player

public operator fun PlayerConfigContentProps.component4(): List<Player> = players

public operator fun PlayerConfigContentProps.component5(): (ChangeEvent<*>) -> Unit = onChange

public operator fun PlayerConfigContentProps.component6(): () -> Unit = onSubmit

public operator fun PlayerConfigContentProps.component7(): (() -> Unit)? = onRemove

public operator fun PlayerConfigContentProps.component8(): (Player) -> Unit = onPlayerChange

public operator fun PlayerConfigContentProps.component9(): Key? = key

public fun ElementType<PlayerConfigContentProps>.create(
  party: PartyDetails,
  boost: Boost? = null,
  player: Player,
  players: List<Player>,
  onChange: (ChangeEvent<*>) -> Unit,
  onSubmit: () -> Unit,
  onRemove: (() -> Unit)? = null,
  onPlayerChange: (Player) -> Unit,
  key: Key? = null,
): ReactNode {
   return create {
         this.party = party
  boost?.let { this.boost = it }
  this.player = player
  this.players = players
  this.onChange = onChange
  this.onSubmit = onSubmit
  onRemove?.let { this.onRemove = it }
  this.onPlayerChange = onPlayerChange
  key?.let { this.key = it }
         }
}
