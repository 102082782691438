package com.zegreatrob.coupling.client.components.graphing.`external`.nivo

import js.objects.jso
import kotlin.Number
import kotlin.String
import react.ElementType

public operator fun NivoAxis.component1(): Number? = tickSize

public operator fun NivoAxis.component2(): Number? = tickPadding

public operator fun NivoAxis.component3(): Number? = tickRotation

public operator fun NivoAxis.component4(): String? = ticksPosition

public operator fun NivoAxis.component5(): String? = legend

public operator fun NivoAxis.component6(): String? = legendPosition

public operator fun NivoAxis.component7(): Number? = legendOffset

public operator fun NivoAxis.component8(): Number? = truncateTickAt

public operator fun NivoAxis.component9(): ElementType<AxisTickProps>? = renderTick

public operator fun NivoAxis.component10(): ((Number) -> String)? = format

public fun NivoAxis(
  tickSize: Number? = null,
  tickPadding: Number? = null,
  tickRotation: Number? = null,
  ticksPosition: String? = null,
  legend: String? = null,
  legendPosition: String? = null,
  legendOffset: Number? = null,
  truncateTickAt: Number? = null,
  renderTick: ElementType<AxisTickProps>? = null,
  format: ((Number) -> String)? = null,
): NivoAxis {
    return jso<NivoAxis> {
         tickSize?.let { this.tickSize = it }
  tickPadding?.let { this.tickPadding = it }
  tickRotation?.let { this.tickRotation = it }
  ticksPosition?.let { this.ticksPosition = it }
  legend?.let { this.legend = it }
  legendPosition?.let { this.legendPosition = it }
  legendOffset?.let { this.legendOffset = it }
  truncateTickAt?.let { this.truncateTickAt = it }
  renderTick?.let { this.renderTick = it }
  format?.let { this.format = it }
         }
}
