package com.zegreatrob.coupling.client.components.stats

import com.zegreatrob.coupling.json.GqlContributionWindow
import com.zegreatrob.coupling.model.ContributionReport
import com.zegreatrob.coupling.model.pairassignmentdocument.CouplingPair
import kotlin.Pair
import kotlin.Unit
import kotlin.collections.List
import react.ChildrenBuilder
import react.FC
import react.Key
import react.create

public fun ChildrenBuilder.PairFrequencyControls(
  pairsContributions: List<Pair<CouplingPair, ContributionReport>>,
  window: GqlContributionWindow,
  setWindow: (GqlContributionWindow) -> Unit,
  key: Key? = null,
  children: ChildrenBuilder.(VisualizationContext) -> Unit = { _ -> },
) {
    val component = (PairFrequencyControls.unsafeCast<FC<PairFrequencyControlsProps>>())
    component {
         this.pairsContributions = pairsContributions
  this.children = fun (cp0: com.zegreatrob.coupling.client.components.stats.VisualizationContext): react.ReactNode = react.Fragment.create(block = { children(cp0) })
  ;
  this.window = window
  this.setWindow = setWindow
  key?.let { this.key = it }
         }
}
