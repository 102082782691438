package com.zegreatrob.coupling.client.components

import kotlin.Unit
import react.ChildrenBuilder
import react.FC
import react.Key
import react.create
import web.cssom.ClassName
import web.cssom.Color

public fun ChildrenBuilder.PageFrame(
  borderColor: Color,
  backgroundColor: Color,
  className: ClassName? = null,
  key: Key? = null,
  children: ChildrenBuilder.() -> Unit = {  -> },
) {
    val component = (PageFrame.unsafeCast<FC<PageFrameProps>>())
    component {
         this.borderColor = borderColor
  this.backgroundColor = backgroundColor
  className?.let { this.className = it }
  this.children = react.Fragment.create(block = { children() })
  key?.let { this.key = it }
         }
}
