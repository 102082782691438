package com.zegreatrob.coupling.client.components.party

import com.zegreatrob.coupling.model.Boost
import com.zegreatrob.coupling.model.party.PartyDetails
import kotlin.Boolean
import kotlin.Unit
import react.ChildrenBuilder
import react.FC
import react.Key
import react.create
import react.dom.events.ChangeEvent

public fun ChildrenBuilder.PartyConfigContent(
  party: PartyDetails,
  boost: Boost? = null,
  isNew: Boolean? = null,
  onChange: (ChangeEvent<*>) -> Unit,
  onSave: () -> Unit,
  onDelete: (() -> Unit)? = null,
  key: Key? = null,
) {
    val component = (PartyConfigContent.unsafeCast<FC<PartyConfigContentProps>>())
    component {
         this.party = party
  boost?.let { this.boost = it }
  isNew?.let { this.isNew = it }
  this.onChange = onChange
  this.onSave = onSave
  onDelete?.let { this.onDelete = it }
  key?.let { this.key = it }
         }
}
